.header {
  border-bottom: 1px solid $gray-400;
}

// Markdown

.md-wrapper {
  h1 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  h2 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important;
  }

  pre {
    border: 0;
    box-shadow: $card-box-shadow;
    background-color: $card-bg;
    border-radius: $card-border-radius;
    padding: $card-py $card-px;

    code {
      font-family: $font-family-code !important;
      font-size: $code-font-size;
      &[class*='language-'] {
        color: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-shadow: none;
      }
    }
  }
}
