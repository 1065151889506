//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: if(isDarkMode(), #3699ff, #009ef7) !default;
$primary-active: if(isDarkMode(), #187de4, #0095e8) !default;
$primary-light: if(isDarkMode(), #212e48, #f1faff) !default;
$primary-inverse: #ffffff !default;

// Success
$success: if(isDarkMode(), #0bb783, #50cd89) !default;
$success-active: if(isDarkMode(), #04aa77, #47be7d) !default;
$success-light: if(isDarkMode(), #1c3238, #e8fff3) !default;
$success-inverse: #ffffff !default;

// Info
$info: if(isDarkMode(), #8950fc, #7239ea) !default;
$info-active: if(isDarkMode(), #7337ee, #5014d0) !default;
$info-light: if(isDarkMode(), #2f264f, #f8f5ff) !default;
$info-inverse: #ffffff !default;

// Danger
$danger: if(isDarkMode(), #f64e60, #f1416c) !default;
$danger-active: if(isDarkMode(), #ee2d41, #d9214e) !default;
$danger-light: if(isDarkMode(), #3a2434, #fff5f8) !default;
$danger-inverse: #ffffff !default;

// Warning
$warning: if(isDarkMode(), #ffa800, #ffc700) !default;
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00) !default;
$warning-light: if(isDarkMode(), #392f28, #fff8dd) !default;
$warning-inverse: #ffffff !default;
